import { useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List, ListItemButton, ListItemText, Typography } from '@mui/material';

// project imports

// assets
import useConfig from 'hooks/useConfig';

// ==============================|| LOCALIZATION ||============================== //

const LocalizationSection = () => {
    const { borderRadius, locale, onChangeLocale } = useConfig();

    const theme = useTheme();

    const [open, setOpen] = useState(false);
    const anchorRef = useRef<any>(null);
    const [language, setLanguage] = useState<string>(locale);

    const handleListItemClick = (
        event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement, MouseEvent> | undefined,
        lng: string
    ) => {
        setLanguage(lng);
        onChangeLocale(lng);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        setLanguage(locale);
    }, [locale]);

    return (
        <>
            <List
                component="nav"
                sx={{
                    width: '100%',
                    minWidth: 200,
                    maxWidth: 280,

                    borderRadius: `${borderRadius}px`,
                    [theme.breakpoints.down('md')]: {
                        maxWidth: 250
                    },
                    display: 'flex'
                }}
            >
                <ListItemButton
                    selected={language === 'en'}
                    onClick={(event) => handleListItemClick(event, 'en')}
                    sx={{
                        backgroundColor: 'transparent !important',
                        '&.MuiListItemButton-root:hover': {
                            backgroundColor: 'transparent !important'
                        },
                        '&.Mui-selected': {
                            color: theme.palette.primary.dark,
                            fontWeight: theme.typography.fontWeightBold
                        },
                        paddingY: 0
                    }}
                >
                    <ListItemText
                        sx={{
                            color: `${theme.palette.dark} !important`
                        }}
                        primary={<Typography>ENG</Typography>}
                    />
                </ListItemButton>
                <Divider orientation="vertical" flexItem />

                <ListItemButton
                    selected={language === 'vie'}
                    onClick={(event) => handleListItemClick(event, 'vie')}
                    sx={{
                        backgroundColor: 'transparent !important',
                        '&.MuiListItemButton-root:hover': {
                            backgroundColor: 'transparent !important'
                        },
                        '&.Mui-selected': {
                            color: theme.palette.primary.dark,
                            fontWeight: theme.typography.fontWeightBold
                        },
                        paddingY: 0
                    }}
                >
                    <ListItemText
                        sx={{
                            color: `${theme.palette.dark} !important`
                        }}
                        primary={<Typography>VIE</Typography>}
                    />
                </ListItemButton>
                <Divider orientation="vertical" flexItem />
                <ListItemButton
                    selected={language === 'zh'}
                    onClick={(event) => handleListItemClick(event, 'zh')}
                    sx={{
                        backgroundColor: 'transparent !important',
                        '&.MuiListItemButton-root:hover': {
                            backgroundColor: 'transparent !important'
                        },
                        '&.Mui-selected': {
                            color: theme.palette.primary.dark,
                            fontWeight: theme.typography.fontWeightBold
                        },
                        paddingY: 0
                    }}
                >
                    <ListItemText
                        sx={{
                            color: `${theme.palette.dark} !important`
                        }}
                        primary={<Typography>中国人</Typography>}
                    />
                </ListItemButton>
            </List>
        </>
    );
};

export default LocalizationSection;
